import React from "react";
import theme from "theme";
import { Theme, Em, Image, Box, Text, Button, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Veritas Legal Insights
			</title>
			<meta name={"description"} content={"Чесність і прозорість на кожному кроці"} />
			<meta property={"og:title"} content={"Veritas Legal Insights"} />
			<meta property={"og:description"} content={"Чесність і прозорість на кожному кроці"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header />
		<Components.Hero>
			<Override slot="text1">
				про нас
			</Override>
			<Override slot="text">
				Створюємо ясність, захищаємо права споживачів
			</Override>
			<Override slot="text2">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Наша історія
				</Em>
			</Override>
		</Components.Hero>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="60%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12:31:02.881Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-4%20%281%29.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="40%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="center"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Станьте на шлях розширення прав і можливостей
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ласкаво просимо на інформаційну сторінку Veritas Legal Insights. Тут ми розкриваємо багатогранність нашої компанії та запрошуємо вас у подорож, щоб зрозуміти, хто ми, що ми відстоюємо та чому ми прагнемо вашого успіху.
				</Text>
				<Button
					type="link"
					href="/services"
					text-align="center"
					text-decoration-line="initial"
					margin="2rem 0px 0px 0px"
				>
					Послуги
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box flex-direction="column" display="flex" text-align="center" align-items="center">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Клієнтоорієнтована технологія
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми використовуємо новітні юридичні технології для покращення надання послуг, забезпечуючи ефективність та прозорість.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					align-items="center"
					text-align="center"
				>
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Комплексні юридичні рішення
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наш підхід не обмежується вирішенням нагальних юридичних проблем, а розглядає ширшу картину для забезпечення довгострокового успіху.
					</Text>
				</Box>
				<Box flex-direction="column" display="flex" text-align="center" align-items="center">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Залучення громади
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми віримо у віддачу та активну участь в ініціативах, які зміцнюють та розширюють можливості нашої спільноти.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="40%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Наша історія:
Основи досконалості
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Наша історія в Veritas Legal Insights починається з прагнення переосмислити поняття юридичної підтримки та адвокації. Народившись з бачення створити юридичну фірму, яка є стовпом доброчесності, розуміння та інновацій, ми присвятили себе наданню послуг нашим клієнтам не менше, ніж на найвищому рівні. В основі нашої діяльності лежить віра в те, що кожен заслуговує на доступ до чітких, лаконічних та ефективних юридичних консультацій, адаптованих до його унікальних обставин та проблем. За роки невтомної реалізації цього бачення ми перетворилися на маяк надії та ясності для тих, хто орієнтується у часто неспокійних водах правової системи.
				</Text>
			</Box>
			<Box
				width="60%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12:31:02.874Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/1-3.jpg?v=2024-04-04T12%3A31%3A02.874Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQ
						</Text>
						<Text as="p" font="--lead" margin="0" color="--darkL2">
							Зрозуміла навігація ваших турбот
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Як зрозуміти, що мені потрібен адвокат?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL2">
									Якщо ви зіткнулися з юридичними проблемами, невизначеністю або можливостями, які мають юридичні наслідки, доцільно проконсультуватися з юристом, щоб зрозуміти свої права, можливості та найкращий курс дій.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Що мені взяти з собою на першу консультацію?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--darkL2">
								Візьміть із собою всі документи, що стосуються вашої справи, список запитань і проблем, які вас турбують, а також готовність відкрито обговорити вашу ситуацію. Чим більше інформації ми матимемо, тим краще ми зможемо Вас проконсультувати.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Як структуровані ваші гонорари?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL2">
									Наші гонорари варіюються залежно від характеру та складності справи. Ми пропонуємо різні варіанти оплати, включаючи погодинну оплату, фіксовану оплату та оплату в разі непередбачених обставин, з урахуванням специфіки вашої справи.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Чи може Veritas Legal Insights представляти мене в суді?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL2">
									Безумовно. Наша команда готова представляти інтереси клієнтів у широкому спектрі правових питань у суді, від цивільних спорів до захисту у кримінальних справах.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});